export const SYSTEM = 'SYSTEM';
export const CENTRALIZED = 'CENTRALIZED';
export const USER = 'USER';

export const ONE_SECOND_IN_MILLISECONDS = 1000;
export const ONE_MINUTE_IN_SECONDS = 60;
export const ONE_HOUR_IN_SECONDS = 60 * 60;
export const ONE_DAY_IN_SECONDS = 60 * 60 * 24;
export const ONE_MONTH_IN_SECONDS = 60 * 60 * 24 * 30;
export const ONE_YEAR_IN_SECONDS = 60 * 60 * 24 * 30 * 12;
export const MINUTES_IN_ONE_HOUR = 60;
export const HOURS_IN_ONE_DAY = 24;
export const DAYS_IN_ONE_MONTH = 30;
export const MONTHS_IN_ONE_YEAR = 12;

const MEM_FACTOR = 1000;

export const ONE_MEGABYTE_IN_BYTES = Math.pow(MEM_FACTOR, 2);
export const ONE_GIGABYTE_IN_BYTES = Math.pow(MEM_FACTOR, 3);
export const ONE_TERABYTE_IN_BYTES = Math.pow(MEM_FACTOR, 4);
export const ONE_GIGABYTE_IN_MEGABYTES = MEM_FACTOR;
export const ONE_TERABYTE_IN_GIGABYTES = MEM_FACTOR;

export enum HANDSHAKE_FAILURES {
  NOT_FOUND = 'ERR_NOT_FOUND',
  INTERNAL_IAM = 'ERR_INTERNAL_IAM',
  INTERNAL_STORAGE = 'ERR_INTERNAL_STORAGE',
  PENDING_CONFIG_EXPIRED = 'ERR_PENDING_CONFIG_EXPIRED',
  PENDING_CONFIG_MISMATCH = 'ERR_PENDING_CONFIG_MISMATCH',
}

export enum APP_CONFIG_KEYS {
  NODE_ENV = 'NODE_ENV',
  PORT = 'PORT',
  DB_URL = 'DB_URL',
  SYSTEM_NAME = 'SYSTEM_NAME',
  SYSTEM_SHORT_NAME = 'SYSTEM_SHORT_NAME',
  SYSTEM_DESCRIPTION = 'SYSTEM_DESCRIPTION',
  SYSTEM_FOOTER = 'SYSTEM_FOOTER',
  API_BASE_URL = 'API_BASE_URL',
  WEB_BASE_URL = 'WEB_BASE_URL',
  MAX_ASSET_SIZE = 'MAX_ASSET_SIZE',
  CORS_ORIGIN = 'CORS_ORIGIN',
  DMS_BASE_URL = 'DMS_BASE_URL',
}

export enum DB_CONFIG_KEYS {
  DEFAULT_USER = 'DEFAULT_USER',
  INTERNAL_IAM = 'INTERNAL_IAM',
  INTERNAL_DMS = 'INTERNAL_DMS',
  DEFAULT_EMAIL_GATEWAY = 'DEFAULT_EMAIL_GATEWAY',
  STORAGE_QUOTA = 'STORAGE_QUOTA',
  USER_QUOTA = 'USER_QUOTA',
  BRUTEFORCE_IDENTITY = 'BRUTEFORCE_IDENTITY',
}

export enum WEB_APP_CONFIG_KEYS {
  PASSWORD_RESET_REDIRECT_ROUTE = 'PASSWORD_RESET_REDIRECT_ROUTE',
  REGISTRATION_REDIRECT_ROUTE = 'REGISTRATION_REDIRECT_ROUTE',
  LOGIN_ROUTE = 'LOGIN_ROUTE',
  SHARED_STORAGE_PREVIEW = 'SHARED_STORAGE_PREVIEW',
  EXTERNAL_AUTH_VERIFICATION_ROUTE = 'EXTERNAL_AUTH_VERIFICATION_ROUTE',
}

export enum EMAIL_CONFIG_KEYS {
  FOOTER_TEXT = 'FOOTER_TEXT',
}

export enum SECURITY_CONFIG_KEYS {
  DDOS_DURATION = 'DDOS_DURATION',
  DDOS_ATTEMPTS = 'DDOS_ATTEMPTS',
}

export enum STORAGE_CONFIG_KEYS {
  USE_INTERNAL_STORAGE = 'USE_INTERNAL_STORAGE',
  RECYCLE_BIN_ENABLED = ' RECYCLE_BIN_ENABLED ',
  RECYCLE_BIN_NUMBER_OF_DAYS = 'RECYCLE_BIN_NO_OF_DAY',
}

export enum PUBLIC_API_TYPES {
  IDENTITIES = 'IDENTITIES',
  STORAGE = 'STORAGE',
  MAPS = 'MAPS',
  SYSTEM = 'SYSTEM',
  BLACKLIST = 'BLACKLIST',
  SECURITY = 'SECURITY',
}

export enum STORAGE_SYNCFUSION_CONFIG_KEYS {
  SYNCFUSION_SERVICE_URL = 'SYNCFUSION_SERVICE_URL',
}

export enum PUBLIC_KEY_GENERATE_TYPES {
  API_KEY = 'API_KEY',
  CLIENT_CREDENTIALS = 'CLIENT_CREDENTIALS',
}

export enum SYSTEMS_SHORT_NAMES {
  BOMS = 'BOMS',
}

export enum RISK_SCORE_PREDICTION_CONFIG_KEYS {
  PREDICTION_API_URL = 'PREDICTION_API_URL',
}

export enum VKYC_URL {
  VKYC_URL = 'VKYC_URL',
  VKYC_API_KEY = 'VKYC_API_KEY',
}
