import { Pipe, PipeTransform } from '@angular/core';

import { CLAIMS_STATUS } from '@shared/constants';

@Pipe({
  name: 'titleCaseEnum',
})
export class TitleCaseEnumPipe implements PipeTransform {
  transform(value: CLAIMS_STATUS): string {
    if (!value) return '';

    // Define if there are lower case exceptions that should not be transformed
    const lowerCaseExceptions = ['OF', 'TO'];

    // Define if there are upper case exceptions that should not be transformed
    const upperCaseExceptions = ['NITF'];

    return value
      .toLowerCase()
      .split('_')
      .map((word) => {
        if (lowerCaseExceptions.includes(word.toUpperCase())) {
          return word.toLowerCase();
        }

        if (upperCaseExceptions.includes(word.toUpperCase())) {
          return word.toUpperCase();
        }

        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }
}
