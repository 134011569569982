import { CUSTOMER_TYPES, POLICY_CLASSES } from '@shared/constants';
import { IPolicy } from '@shared/interfaces';

const isPolicyTypeAndClass = (
  policy: any,
  type: CUSTOMER_TYPES,
  classType: POLICY_CLASSES
): boolean => {
  return (
    policy?.customer_information?.type === type &&
    policy?.customer_information?.class_type === classType
  );
};

export const getPolicyTypeAndClass = (
  policy: IPolicy
): {
  isMotorIndividual: boolean;
  isMotorFleet: boolean;
  isMedicalIndividual: boolean;
  isMedicalGroup: boolean;
  isTravelGroup: boolean;
  isTravelIndividual: boolean;
  isFireIndividual: boolean;
  isFireGroup: boolean;
  isMarineIndividual: boolean;
  isMarineGroup: boolean;
  isMiscIndividual: boolean;
  isMiscGroup: boolean;
  isPackage: boolean;
} => {
  return {
    isMotorIndividual: isPolicyTypeAndClass(
      policy,
      CUSTOMER_TYPES.INDIVIDUAL,
      POLICY_CLASSES.MOTOR
    ),
    isMotorFleet: isPolicyTypeAndClass(
      policy,
      CUSTOMER_TYPES.FLEET,
      POLICY_CLASSES.MOTOR
    ),
    isMedicalIndividual: isPolicyTypeAndClass(
      policy,
      CUSTOMER_TYPES.INDIVIDUAL,
      POLICY_CLASSES.MEDICAL
    ),
    isMedicalGroup: isPolicyTypeAndClass(
      policy,
      CUSTOMER_TYPES.ORGANIZATION,
      POLICY_CLASSES.MEDICAL
    ),
    isTravelGroup: isPolicyTypeAndClass(
      policy,
      CUSTOMER_TYPES.GROUP,
      POLICY_CLASSES.TRAVEL
    ),
    isTravelIndividual: isPolicyTypeAndClass(
      policy,
      CUSTOMER_TYPES.INDIVIDUAL,
      POLICY_CLASSES.TRAVEL
    ),
    isFireIndividual: isPolicyTypeAndClass(
      policy,
      CUSTOMER_TYPES.INDIVIDUAL,
      POLICY_CLASSES.FIRE
    ),
    isFireGroup: isPolicyTypeAndClass(
      policy,
      CUSTOMER_TYPES.GROUP,
      POLICY_CLASSES.FIRE
    ),
    isMarineIndividual: isPolicyTypeAndClass(
      policy,
      CUSTOMER_TYPES.INDIVIDUAL,
      POLICY_CLASSES.MARINE
    ),
    isMarineGroup: isPolicyTypeAndClass(
      policy,
      CUSTOMER_TYPES.GROUP,
      POLICY_CLASSES.MARINE
    ),
    isMiscIndividual: isPolicyTypeAndClass(
      policy,
      CUSTOMER_TYPES.INDIVIDUAL,
      POLICY_CLASSES.MISCELLANEOUS
    ),
    isMiscGroup: isPolicyTypeAndClass(
      policy,
      CUSTOMER_TYPES.GROUP,
      POLICY_CLASSES.MISCELLANEOUS
    ),
    isPackage:
      policy?.customer_information?.class_type === POLICY_CLASSES.PACKAGES,
  };
};
