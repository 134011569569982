export enum VEHICLE_USAGE {
  PERSONAL = 'PERSONAL',
  HIRE = 'HIRE',
  RENT = 'RENT',
}

export enum GOOD_TYPE {
  OWN_GOOD = 'OWN GOOD',
  HIRE = 'HIRE',
  CONTRACT = 'CONTRACT',
}

export enum POLICY_TYPE {
  THIRD_PARTY = 'THIRD PARTY',
  COMPREHENSIVE = 'COMPREHENSIVE',
}

export enum CUSTOMER_SEGMENTATION {
  NORMAL = 'NORMAL',
  VIP = 'VIP',
}

export enum MODE_OF_INTIMATION {
  CALL_CENTER = 'CALL CENTER',
  MOBILE_APP = 'MOBILE APP',
  HOSPITAL = 'HOSPITAL',
  GARAGE = 'GARAGE',
}

export enum POLICE_REPORTED_BY {
  INSURED = 'INSURED',
  DRIVER = 'DRIVER',
  OTHER = 'OTHER',
}

export enum MEASURING_UNITS {
  COUNT = 'COUNT',
  KILOGRAMS = 'KILOGRAMS',
  LITRES = 'LITRES',
}

export enum TYPE_OF_CLAIM {
  THIRD_PARTY = 'THIRD PARTY',
  OWN_DAMAGE = 'OWN DAMAGE',
  THEFT_OF_VEHICLE = 'THEFT OF VEHICLE',
  THEFT_OF_SPARE_PARTS = 'THEFT OF SPARE PARTS',
}

export enum CLAIMS_DOC_TYPES {
  //COMMON
  SIGNED_CLAIM_FORM = 'SIGNED_CLAIM_FORM',
  ADDITIONAL_DOCUMENTS = 'ADDITIONAL_DOCUMENTS',

  OTHER = 'OTHER',

  //MOTOR
  DRIVING_LICENSE = 'DRIVING_LICENSE',
  ACCIDENT_SKETCH = 'ACCIDENT_SKETCH',
  OWN_DAMAGE_GARAGE_ESTIMATION = 'OWN_DAMAGE_GARAGE_ESTIMATION',
  POLICE_STATEMENT = 'POLICE_STATEMENT',
  //MOTOR REPORT AND DOC
  VALUATION = 'VALUATION_REPORT',
  DISMANTLE = 'DISMANTLE_REPORT',
  VEHICLE_INVENTORY = 'VEHICLE_INVENTORY_REPORT',

  //MEDICAL
  FINAL_DIAGNOSIS_REPORT = 'FINAL_DIAGNOSIS_REPORT',
  HOSPITAL_BILL = 'HOSPITAL_BILL',
  HOSPITAL_BILL_DOC = 'HOSPITAL_BILL_DOCUMENT',
  SIGNED_DOCTOR_FORM = 'SIGNED_DOCTOR_FORM',
  DIAGNOSIS_REPORT = 'DIAGNOSIS_REPORT',

  //FEEDBACK DOCUMENTS - not in doc section
  FEEDBACK_DOC = 'FEEDBACK_DOC',

  //REQUEST REPORT - not in doc section
  REQUEST_REPORT = 'REQUEST_REPORT',

  //CLAIM HANDLER CONFIRMATION - not in doc section
  CLAIM_HANDLER_CONFIRMATION = 'CLAIM_HANDLER_CONFIRMATION',
}

export enum CLAIMS_REFERENCE_CATEGORIES {
  NATURE_OF_LOSS = 'NATURE OF LOSSES',
  SPARE_PARTS = 'SPARE PARTS',
  INSURANCE_COMPANIES = 'INSURANCE COMPANIES',
}

export enum CLAIMS_SECTIONS {
  CLAIM_FORM = 'CLAIM FORM',
  ANALYSIS = 'ANALYSIS',
  SALVAGES = 'SALVAGES',
  ACTIVITY_HISTORY = 'ACTIVITY HISTORY',
  ONSITE_SESSION = 'ONSITE_SESSION',
  VIRTUAL_SESSION = 'VIRTUAL_SESSION',
  ENGINEER_SESSION = 'ENGINEER_SESSION',
  GARAGE_SESSION = 'GARAGE_SESSION',
  DOCUMENTS = 'DOCUMENTS',
  REQUEST_REPORT = 'REQUEST_REPORT',
  PAYMENT_APPROVAL = 'PAYMENT_APPROVAL',
  SETTLEMENT_SUGGESTION = 'SETTLEMENT_SUGGESTION',
  PAYMENT_APPROVAL_ADD = 'PAYMENT_APPROVAL_ADD',
  SUPERVISOR_DECISION = 'SUPERVISOR_DECISION',
  CLAIM_HANDLER_DECISION = 'CLAIM_HANDLER_DECISION',
  RE_APPEAL = 'RE_APPEAL',
  SUPERVISOR_CONFIRMATION = 'SUPERVISOR_CONFIRMATION',

  MEDICAL_ONSITE_SESSION = 'MEDICAL_ONSITE_SESSION',
  MEDICAL_PAYMENT_APPROVAL = 'MEDICAL_PAYMENT_APPROVAL',
  MEDICAL_SUPERVISOR_DECISION = 'MEDICAL_SUPERVISOR_DECISION',

  TRAVEL_ONSITE_SESSION = 'TRAVEL_ONSITE_SESSION',
  TRAVEL_PAYMENT_APPROVAL = 'TRAVEL_PAYMENT_APPROVAL',
  TRAVEL_SUPERVISOR_DECISION = 'TRAVEL_SUPERVISOR_DECISION',
}

export enum CLAIM_HANDLER_ASSIGNEES {
  VIRTUAL_ASSESSOR = 'VIRTUAL ASSESSOR',
  ONSITE_INSPECTOR = 'ONSITE INSPECTOR',
  ENGINEER = 'ENGINEER',
  GARAGE_INSPECTOR = 'GARAGE INSPECTOR',

  MEDICAL_ONSITE_INSPECTION = 'MEDICAL_ONSITE_INSPECTION INSPECTION',
}

export enum ASSESSMENT_STATUS {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  DRAFT = 'DRAFT',
  REJECTED = 'REJECTED',
  COMPLETED = 'COMPLETED',

  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
}

export enum ASSESSMENT_PAYMENT_STATUS {
  PAYMENT_PENDING = 'Payment Pending',
  PAID = 'Paid',
}

export enum SALVAGES_STATUS {
  REQUESTED = 'REQUESTED',
}

export enum CLAIMS_STATUS {
  //can perform by - claim handler / desk assessor
  INTIMATED = 'INTIMATED',
  DRAFT = 'DRAFT',
  PENDING_ASSESSMENT = 'PENDING_ASSESSMENT',
  PENDING_ONSITE_INSPECTION = 'PENDING_ONSITE_INSPECTION',
  PENDING_VIRTUAL_INSPECTION = 'PENDING_VIRTUAL_INSPECTION',
  PENDING_ENGINEER_INSPECTION = 'PENDING_ENGINEER_INSPECTION',
  //can perform by - engineer
  PENDING_GARAGE_INSPECTION = 'PENDING_GARAGE_INSPECTION',

  //can perform by assessors(onsite/ garage/ engineer)
  ACCEPTED_ONSITE_INSPECTION = 'ACCEPTED_ONSITE_INSPECTION',
  ACCEPTED_VIRTUAL_INSPECTION = 'ACCEPTED_VIRTUAL_INSPECTION',
  ACCEPTED_ENGINEER_INSPECTION = 'ACCEPTED_ENGINEER_INSPECTION',
  ACCEPTED_GARAGE_INSPECTION = 'ACCEPTED_GARAGE_INSPECTION',

  REJECTED_ONSITE_INSPECTION = 'REJECTED_ONSITE_INSPECTION',
  REJECTED_VIRTUAL_INSPECTION = 'REJECTED_VIRTUAL_INSPECTION',
  REJECTED_ENGINEER_INSPECTION = 'REJECTED_ENGINEER_INSPECTION',
  REJECTED_GARAGE_INSPECTION = 'REJECTED_GARAGE_INSPECTION',

  COMPLETED_ONSITE_INSPECTION = 'COMPLETED_ONSITE_INSPECTION',
  COMPLETED_VIRTUAL_INSPECTION = 'COMPLETED_VIRTUAL_INSPECTION',
  COMPLETED_ENGINEER_INSPECTION = 'COMPLETED_ENGINEER_INSPECTION',
  COMPLETED_GARAGE_INSPECTION = 'COMPLETED_GARAGE_INSPECTION',

  ACTIVE_VIRTUAL_INSPECTION = 'ACTIVE_VIRTUAL_INSPECTION',
  EXPIRED_VIRTUAL_INSPECTION = 'EXPIRED_VIRTUAL_INSPECTION',

  CANCELLED_ONSITE_INSPECTION = 'CANCELLED_ONSITE_INSPECTION',
  CANCELLED_VIRTUAL_INSPECTION = 'CANCELLED_VIRTUAL_INSPECTION',
  CANCELLED_ENGINEER_INSPECTION = 'CANCELLED_ENGINEER_INSPECTION',
  CANCELLED_GARAGE_INSPECTION = 'CANCELLED_GARAGE_INSPECTION',

  DRAFT_ONSITE_INSPECTION = 'DRAFT_ONSITE_INSPECTION',
  DRAFT_VIRTUAL_INSPECTION = 'DRAFT_VIRTUAL_INSPECTION',
  DRAFT_ENGINEER_INSPECTION = 'DRAFT_ENGINEER_INSPECTION',
  DRAFT_GARAGE_INSPECTION = 'DRAFT_GARAGE_INSPECTION',

  //can perform by - claim handler only
  SENT_TO_NITF = 'SENT_TO_NITF',
  SENT_TO_LITIGATION = 'SENT_TO_LITIGATION',
  SENT_TO_REINSURER = 'SENT_TO_REINSURER',
  REFERRED_FOR_INVESTIGATION = 'REFERRED_FOR_INVESTIGATION',
  ON_HOLD = 'ON_HOLD',

  //can perform by - supervisor
  APPROVED = 'APPROVED',
  REDO = 'REDO',
  REFER = 'REFER',

  //can perform by - claim handler / supervisor
  PENDING_AUTHORIZATION = 'PENDING_AUTHORIZATION',
  REJECTED = 'REJECTED',

  //other
  PENDING_RE_INSPECTION = 'PENDING_RE_INSPECTION',
  PENDING_REVIEW = 'PENDING_REVIEW',
  COMPLETED = 'COMPLETED',
  PARTIALLY_PAID = 'PARTIALLY PAID',
}

export enum CLAIM_HANDLER_DECISION_TYPES {
  HOLD = 'HOLD',
  REJECT = 'REJECT',
  REFER_TO_REINSURER = 'REFER TO REINSURER',
  REFER_TO_NITF = 'REFER TO NITF',
  REFER_TO_INVESTIGATION = 'REFER TO INVESTIGATION',
}

export enum SUPERVISOR_DECISIONS {
  APPROVE = 'APPROVE',
  REDO = 'REDO',
  REFER = 'REFER',
  REJECT = 'REJECT',
}

export enum CLAIMS_ROLES {
  CLAIM_HANDLER = 'CLAIM_HANDLER',
  CLAIM_DESK_ASSESSOR = 'CLAIM_DESK_ASSESSOR',
  CLAIM_ONSITE_ASSESSOR = 'CLAIM_ONSITE_ASSESSOR',
  CLAIM_ENGINEER = 'CLAIM_ENGINEER',
  CLAIM_GARAGE_INSPECTOR = 'CLAIM_GARAGE_INSPECTOR',
  CLAIM_SUPERVISOR = 'CLAIM_SUPERVISOR',

  //for customer app's purpose
  CUSTOMER = 'CUSTOMER',
}

export enum CLAIMS_ACTION_TYPES {
  CLAIM_DRAFT = 'CLAIM_DRAFT',
  CLAIM_INTIMATE = 'CLAIM_INTIMATE',
  ASSESSMENT_ADD = 'ASSESSMENT_ADD',
  ASSESSMENT_RE_ASSIGN = 'ASSESSMENT_RE_ASSIGN',
  FEEDBACK_ADD = 'FEEDBACK_ADD',
  FEEDBACK_UPDATE = 'FEEDBACK_UPDATE',
  FEEDBACK_DELETE = 'FEEDBACK_DELETE',
  SALVAGE_ADD = 'SALVAGE_ADD',
  SALVAGE_DELETE = 'SALVAGE_DELETE',
  DOCUMENT_ADD = 'DOCUMENT_ADD',
  DOCUMENT_DELETE = 'DOCUMENT_DELETE',
  REQUEST_REPORT_ADD = 'REQUEST_REPORT_ADD',
  REQUEST_REPORT_DELETE = 'REQUEST_REPORT_DELETE',
  SETTLEMENT_SUGGESTION_ADD = 'SETTLEMENT_SUGGESTION_ADD',
  PAYMENT_APPROVAL_ADD = 'PAYMENT_APPROVAL_ADD',
  CLAIM_HANDLER_DECISION_ADD = 'CLAIM_HANDLER_DECISION_ADD',
  SUPERVISOR_DECISION_ADD = 'SUPERVISOR_DECISION_ADD',
  RE_APPEAL_ADD = 'RE_APPEAL_ADD',
  CONFIRMATION_ADD = 'CONFIRMATION_ADD',
  CONFIRMATION_DELETE = 'CONFIRMATION_DELETE',
  ASSESSMENT_ACCEPTED = 'ASSESSMENT_ACCEPTED',
  ASSESSMENT_REJECTED = 'ASSESSMENT_REJECTED',
  ASSESSMENT_COMPLETED = 'ASSESSMENT_COMPLETED',
}

export enum ACCIDENT_TYPES {
  FRONT = 'FRONT',
  BACK = 'BACK',
  LEFT_SIDE = 'LEFT SIDE',
  RIGHT_SIDE = 'RIGHT SIDE',
  TOP = 'TOP',
}

export enum LABOUR_CHARGE {
  REMOVE_REFITTING = 'Remove and Refitting',
  REPAIR = 'Repair',
  REPLACEMENT = 'Replacement',
  REPAINT = 'Repaint',
}

export enum CLAIMS_PAYMENT_OPTIONS {
  BANK_TRANSFER = 'Bank Transfer',
  MOBILE_TRANSFER = 'Mobile Transfer',
}

//medical-bulk
export enum CLAIMS_BULK_FIELDS {
  POLICY_NO = 'policy_no',
  NIC_PASSPORT = 'member_nic_passport_no',
  DEPENDENT_NIC_PASSPORT = 'dependent_nic_passport_no',
  EMPLOYEE_ID = 'employee_id',
  EMPLOYEE_NAME = 'employee_name',
  PATIENT_NAME = 'patient_name',
  RELATIONSHIP = 'relationship',
  NATURE_OF_ILLNESS = 'nature_of_illness',
  MEDICAL_OFFICER = 'medical_officer',
  HOSPITAL = 'hospital',
  ADMITTED_DATE = 'admitted_date',
  DISCHARGED_DATE = 'discharged_date',
  BILLED_DATE = 'billed_date',
  BILL_AMOUNT = 'bill_amount',
}

//travel covers
export enum CLAIM_TRAVEL_COVERS {
  PERSONAL_ACCIDENT = 'PERSONAL ACCIDENT',
  HOSPITALIZATION = 'HOSPITALIZATION',
  LOSS_OF_PASSPORT = 'LOSS OF PASSPORT',
  LOSS_OF_LUGGAGE = 'LOSS OF LUGGAGE',
  COMMON = 'COMMON',
}

//medical covers
export enum CLAIM_MEDICAL_COVERS {
  INDOOR = 'INDOOR',
  OPD = 'OPD',
  HOSPITALIZATION = 'HOSPITALIZATION',
  DENTAL = 'DENTAL',
  SPECTACLE = 'SPECTACLE',
  BIRTH_OF_TWINS = 'BIRTH OF TWINS',
  COMMON = 'COMMON',
}

//only for customer mobile app
export enum CLAIMS_FILTER_TYPES {
  ALL = 'ALL',
  INTIMATED = 'INTIMATED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  DRAFT = 'DRAFT',
}
